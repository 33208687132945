/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */
import { IFormData } from '@client/forms'

export function customSectionTransformer(
  transformedData: IFormData,
  queryData: any,
  sectionId: string
) {
  if (!queryData[sectionId]) {
    transformedData[sectionId] = {
      ...(transformedData[sectionId] || {}),
      /*
       * The __EMPTY__ key is used to ensure that the section is not removed by
       * the form data transformer in packages/client/src/transformer/index.ts
       * Object.keys(transformedData[section.id]).length < 1
       */
      __EMPTY__: ''
    }
  }
  return transformedData
}
